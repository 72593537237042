<template>
  <div class="block whoweare">
    <div class="content">
      <div class="content-top content-flex">
        <div class="content-text">
          <h2 class="xxl-title">{{ t(`whoweare.title.${currentPage}`) }}</h2>
          <p class="text">{{ t("whoweare.text.intro") }}</p>
        </div>
        <div class="content-image">
          <img
            :src="`${$URL}assets/img/whoweare/whoweare_expert.webp`"
            alt=""
          />
        </div>
      </div>
      <div class="content-middle content-flex">
        <div class="content-image">
          <img
            :src="`${$URL}assets/img/whoweare/whoweare_milena.webp`"
            alt=""
          />
        </div>
        <div class="content-text">
          <p class="s-title" v-if="currentPage === 'wine'">
            <router-link
              :to="{
                name: 'Art',
                params: { locale: `${$route.params.locale}`, trans: true },
              }"
            >
              {{ t("whoweare.subtitle.milena") }}
            </router-link>
          </p>
          <p class="s-title" v-if="currentPage === 'art'">
            {{ t("whoweare.subtitle.milena") }}
          </p>
          <p class="text">{{ t("whoweare.text.milena") }}</p>
        </div>
      </div>
      <div class="content-bottom content-flex">
        <div class="content-image">
          <img :src="`${$URL}assets/img/whoweare/whoweare_loic.webp`" alt="" />
        </div>
        <div class="content-text">
          <p class="s-title" v-if="currentPage === 'art'">
            <router-link
              :to="{
                name: 'Wine',
                params: { locale: `${$route.params.locale}`, trans: true },
              }"
            >
              {{ t("whoweare.subtitle.loic") }}
            </router-link>
          </p>
          <p class="s-title" v-if="currentPage === 'wine'">
            {{ t("whoweare.subtitle.loic") }}
          </p>
          <p class="text">{{ t("whoweare.text.loic") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "WhoWeAre",
  inject: ["$URL"],
  props: {
    currentPage: {
      type: String,
      default: () => {
        return "wine";
      },
    },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
.whoweare {
  display: flex;
  justify-content: space-around;
  @include respond-to("md-down") {
    padding-bottom: 06rem;
  }
  & h2 {
    color: #473d38;
    white-space: pre-line;
    line-height: 1em;
    font-size: 5.625rem;
  }
  .content-flex {
    display: flex;
    justify-content: space-between;
    gap: 5rem;
    margin-bottom: 8rem;

    @include respond-to("md-down") {
      flex-direction: column;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.content-top {
      align-items: flex-start;
      h2 {
        margin-bottom: 5rem;
        margin-top: 5rem;
      }
    }

    &.content-middle {
      align-items: flex-end;

      .content-image {
        @include respond-to("md-down") {
          order: 2;

          img {
            max-width: 300px;
          }
        }
      }
    }

    &.content-bottom {
      align-items: center;
      .content-image {
        text-align: center;

        @include respond-to("md-down") {
          order: 2;

          img {
            max-width: 300px;
          }
        }
      }
    }

    .s-title {
      margin-bottom: 2.5rem;
      padding: 0;
      text-transform: none;
    }
    .text {
      margin-bottom: 0;
    }

    .content-image,
    .content-text {
      width: 100%;
    }
    .content-image {
      img {
        max-width: 100%;

        @include respond-to("md-down") {
          width: 100% !important;
        }
      }

      @include respond-to("md-down") {
        text-align: center;
      }
    }
  }
}
</style>
