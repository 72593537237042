<template>
  <div class="block planexperience dark">
    <div class="content">
      <h2 class="xl-title">{{ t("planexperience.title") }}</h2>
      <p class="text">{{ t("planexperience.text.0") }}</p>
      <div class="links">
        <router-link
          :to="{
            name: 'Contact',
            params: { locale: `${$route.params.locale}`, trans: true },
          }"
          >{{ t("planexperience.link.contact") }}</router-link
        >
        <router-link
          :to="{
            name: 'Experiences',
            params: { locale: `${$route.params.locale}`, trans: true },
          }"
          >{{ t("planexperience.link.experience") }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "PlanExperience",
  inject: ["$URL"],
  setup() {
    const { t } = useI18n();

    return { t };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
.planexperience {
  background-color: #1d281d;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @include respond-to("md-down") {
    flex-direction: column;
  }
  & .content {
    max-width: 45rem;
    text-align: center;
    color: #f5ebe6;
    & p {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      opacity: 0.8;
      font-size: 14px;
    }
    & h2 {
      margin-bottom: 5rem;
      color: #f5ebe6;
      @include respond-to("md-down") {
        margin-bottom: 2rem;
        margin-top: 2rem;
      }
    }
    & a {
      background: rgba(255, 255, 255, 0.1);
      font-size: 14px;
      padding: 20px 40px;
      text-decoration: none;
      display: inline-block;
      @include respond-to("md-down") {
        display: block;
        width: 70%;
        margin: 0 auto;
      }
    }
    .links {
      align-items: center;
      display: flex;
      justify-content: center;
      gap: 2rem;
      margin-top: 2rem;
      @include respond-to("xs-down") {
        flex-direction: column;
      }
    }
  }
}
</style>
