<template>
  <div class="contact container">
    <div class="block">
      <div class="title">
        <h1 class="xl-title">{{ t("contact.title") }}</h1>
      </div>
      <div class="form">
        <div class="top">
          <p class="text">{{ t("contact.form.text") }}</p>
          <span class="text">{{
            t("contact.form.mail").replace("[at]", "@")
          }}</span>
        </div>
        <transition name="fade" mode="out-in">
          <div v-if="!isSub">
            <form ref="formD" @submit.prevent="sub()">
              <span class="s-title">{{ t("contact.form.interess") }}</span>
              <div class="select">
                <input
                  type="radio"
                  name="interest"
                  id="art"
                  ref="art"
                  value="art"
                />
                <label for="interest" @click="$refs.art.checked = true">{{
                  t("contact.form.art")
                }}</label>
                <input
                  type="radio"
                  name="interest"
                  id="wine"
                  ref="wine"
                  value="wine"
                />
                <label for="interest" @click="$refs.wine.checked = true">{{
                  t("contact.form.wine")
                }}</label>
                <input
                  type="radio"
                  name="interest"
                  id="both"
                  ref="both"
                  value="wine & art"
                />
                <label for="interest" @click="$refs.both.checked = true">{{
                  t("contact.form.both")
                }}</label>
              </div>
              <input
                type="text"
                name="name"
                :placeholder="t('contact.form.name')"
              />
              <input
                type="email"
                name="email"
                :placeholder="t('contact.form.email')"
              />
              <textarea
                name="message"
                cols="30"
                rows="10"
                :placeholder="t('contact.form.message')"
              ></textarea>
              <span class="privacy">{{ t("contact.form.privacy") }}</span>
              <div class="submit">
                <input type="submit" :value="t('contact.form.send')" />
              </div>
            </form>
          </div>
          <div v-else>
            <span class="s-title">{{ t("contact.success.title") }}</span>
            <p class="text">{{ t("contact.success.message") }}</p>
          </div>
        </transition>
      </div>
    </div>
    <div class="block separation"><hr /></div>
    <Newsletter additionalClasses="block horizontal fullsize" />
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import axios from "axios";
import { inject, ref } from "vue";
import Newsletter from "@/components/Newsletter";

export default {
  name: "Contact",
  components: {
    Newsletter,
  },
  setup() {
    const { t } = useI18n();
    const formD = ref(null);
    const $URL = inject("$URL");
    const isSub = ref(false);

    const sub = () => {
      const data = new FormData(formD.value);
      axios({
        method: "post",
        url: $URL + "mail.php",
        data: data,
      }).then(() => {
        isSub.value = true;
      });
    };

    return { t, formD, sub, isSub };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
.block {
  padding-top: 16em;
  display: flex;
  justify-content: space-around;
  @include respond-to("md-down") {
    flex-direction: column;
    padding-top: 10rem;
  }
}
h1 {
  max-width: 35rem;
  @include respond-to("md-down") {
    max-width: 100%;
    margin-bottom: 2rem;
  }
}

.form {
  width: 40%;
  @include respond-to("md-down") {
    width: 100%;
  }
  & p {
    max-width: 85%;
    @include respond-to("md-down") {
      max-width: 100%;
    }
  }
  & span {
    display: block;
    line-height: inherit;
    &:not(.s-title) {
      font-weight: 500;
      margin: 0.5em 0;
    }
  }
}
.select {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
}
label {
  text-transform: uppercase;
  font-family: "IvyJournal";
  margin-right: 1em;
  margin-left: 0.1em;
}
input[type="radio"] {
  -webkit-appearance: none;
  border-radius: 50%;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 18px;
  height: 18px;
}
input[type="radio"]:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  margin: 2px auto;
  border-radius: 50%;
}
input[type="radio"]:checked:before {
  background: #473d38;
}

input:not([type="radio"], [type="submit"]) {
  display: block;
  height: 5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: 2rem;
  padding: 0 2em;
  font-size: 1.125rem;
  @include respond-to("md-down") {
    height: 4rem;
  }
}

textarea {
  resize: vertical;
  min-height: 20rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: 2rem;
  padding: 2em 2em;
  font-size: 1.125rem;
  line-height: 1.5rem;
}
.privacy {
  opacity: 0.3;
  display: block;
  text-align: center;
}
.submit {
  width: 100%;
  display: flex;
  justify-content: center;
}
.submit input {
  cursor: pointer;
  margin-top: 2rem;
  height: auto;
  padding: 20px 40px;
  background: rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #6a605b;
  @include respond-to("md-down") {
    width: inherit;
    display: block;
  }
}
.block.separation {
  padding-bottom: 0;
  padding-top: 0;
  hr {
    background-color: rgba(0, 0, 0, 0.1);
    height: 4px;
    width: 100%;
  }
}
</style>
