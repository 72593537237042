<template>
  <div class="we-support container">
    <div class="block">
      <div class="title">
        <h1 class="xl-title">{{ t("we_support.title") }}</h1>
      </div>
      <div class="column-left">
        <div class="description">
          <p class="text">{{ t("we_support.description") }}</p>
        </div>
        <div class="block-logos">
          <div class="subtitle">
            <p class="text">{{ t("we_support.subtitle") }}</p>
          </div>
          <div class="logo-list">
            <div class="logo-item">
              <p class="text">“Sauvons les Forets Françaises”:</p>
              <div class="logo-multiple">
                <a
                  href="https://www.fondation-patrimoine.org/fondation-du-patrimoine/fondation-fransylva"
                  title="Fondation du Patrimoine"
                  target="_blank"
                >
                  <img
                    :src="`${$URL}assets/img/we_support/logo_fondation_patrimoine.webp`"
                    alt="logo Fondation du Patrimoine"
                  />
                </a>
                <a
                  href="https://www.fondation-patrimoine.org/fondation-du-patrimoine/fondation-fransylva"
                  title="Fondation Fransylva"
                  target="_blank"
                >
                  <img
                    :src="`${$URL}assets/img/we_support/logo_fransylva.webp`"
                    alt="logo Fransylva"
                  />
                </a>
              </div>
            </div>
            <div class="logo-item">
              <a
                href="https://refettorioparis.com/index.html"
                title="Fondation du Patrimoine"
                target="_blank"
              >
                <img
                  :src="`${$URL}assets/img/we_support/logo_refettorio.webp`"
                  alt="logo Refettorio France"
                />
              </a>
            </div>
            <div class="logo-item">
              <a
                href="https://www.therootsfund.org"
                title="The Roots Fund"
                target="_blank"
              >
                <img
                  :src="`${$URL}assets/img/we_support/logo_therootsfund.webp`"
                  alt="logo The Roots Fund"
                />
              </a>
            </div>
            <div class="logo-item">
              <a
                href="https://fondation.bsb-education.com"
                title="Fondation BSB"
                target="_blank"
              >
                <img
                  :src="`${$URL}assets/img/we_support/logo_fondation_bsb.webp`"
                  alt="logo Fondation BSB"
                />
              </a>
            </div>
            <div class="logo-item">
              <a
                href="https://www.roadsideterroir.com"
                title="Roadside Terroir"
                target="_blank"
              >
                <img
                  :src="`${$URL}assets/img/we_support/logo_rt.webp`"
                  alt="logo Roadside Terroir"
                />
              </a>
            </div>
            <div class="logo-item">
              <a
                href="https://www.roadsideterroir.com"
                title="Association L'abbaye Saint-Vivant de Vergy"
                target="_blank"
                class="s-title"
              >
                Association L'abbaye<br />Saint-Vivant de Vergy
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "We Support",
  inject: ["$URL"],
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
.block {
  padding-top: 16em;
  display: flex;
  justify-content: space-around;
  @include respond-to("md-down") {
    flex-direction: column;
    padding-top: 10rem;
  }
}
h1 {
  max-width: 40rem;
  @include respond-to("md-down") {
    max-width: 100%;
    margin-bottom: 2rem;
  }
}

.column-left {
  width: 40%;
  @include respond-to("md-down") {
    width: 100%;
  }
  & p {
    max-width: 85%;
    margin-bottom: 1rem;
    @include respond-to("md-down") {
      max-width: 100%;
    }
  }
}
.logo-item {
  padding: 2rem 0;
  border-bottom: 1px solid lightgrey;
}
.logo-item:first-child {
  padding-top: 0;
}
.logo-item:last-child {
  border-bottom: none;
}
.logo-item img {
  max-height: 100px;
  width: auto !important;
  @include respond-to("md-down") {
    max-height: 70px;
  }
}
.logo-multiple {
  align-items: flex-end;
  display: flex;
  gap: 20px;
}
.logo-multiple a:first-child img {
  mix-blend-mode: multiply;
}
</style>
