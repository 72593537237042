<template>
  <div :class="['newsletter', additionalClasses, context]">
    <div class="block-content">
      <div class="title">
        <h2 v-if="context == 'component'" class="xl-title">
          {{ t("newsletter.title") }}
        </h2>
        <h1 v-if="context == 'page'" class="xl-title">
          {{ t("newsletter.pagetitle") }}
        </h1>
      </div>
      <div
        class="ff-61bb500fd2fc90968d6f408e newsletter-form-container"
        data-ff-el="root"
        data-ff-version="3"
        data-ff-type="inline"
        data-ff-name="ribbonBanner"
      >
        <!--tpl {% block config %} tpl-->
        <div
          data-ff-el="config"
          data-ff-config="eyJ0cmlnZ2VyIjp7Im1vZGUiOiJpbW1lZGlhdGVseSIsInZhbHVlIjowfSwib25TdWNjZXNzIjp7Im1vZGUiOiJtZXNzYWdlIiwibWVzc2FnZSI6IiIsInJlZGlyZWN0VXJsIjoiIn0sImNvaSI6ZmFsc2UsInNob3dGb3JSZXR1cm5WaXNpdG9ycyI6dHJ1ZSwibm90aWZpY2F0aW9uIjpmYWxzZX0="
          style="display: none"
        ></div>
        <div v-if="context == 'page'" class="description">
          {{ t("newsletter.text") }}
        </div>
        <!--tpl {% endblock %} tpl-->
        <div class="ff-61bb500fd2fc90968d6f408e__container">
          <form
            class="ff-61bb500fd2fc90968d6f408e__form"
            target="_blank"
            ref="form"
            action="https://form.flodesk.com/forms/61bb500fd2fc90968d6f408e/submit"
            method="post"
            data-ff-el="form"
          >
            <div
              class="
                ff-61bb500fd2fc90968d6f408e__content
                fd-form-content
                form
                content
              "
              data-ff-el="content"
            >
              <input
                class="
                  ff-61bb500fd2fc90968d6f408e__control
                  fd-form-control
                  required
                  email
                "
                type="text"
                name="email"
                :placeholder="t('newsletter.email')"
                data-ff-tab="email::submit"
                required
              />
              <input
                type="text"
                name="confirm_email_address"
                style="display: none"
              />

              <input
                type="submit"
                class="ff-61bb500fd2fc90968d6f408e__button fd-btn"
                data-ff-el="submit"
                data-ff-tab="submit"
                :value="t('newsletter.submit')"
                @click="handleClick()"
              />
            </div>
            <div
              class="
                ff-61bb500fd2fc90968d6f408e__success
                fd-form-success
                success
                hidden
              "
              data-ff-el="success"
            >
              <span class="text" data-paragraph="true"
                >Thank you for subscribing!</span
              >
            </div>
            <div
              class="
                ff-61bb500fd2fc90968d6f408e__error
                fd-form-error
                error
                hidden
              "
              data-ff-el="error"
            ></div>
          </form>
        </div>

        <div v-if="context == 'component'" class="social-links">
          <a
            href="https://www.instagram.com/hautes_cotes/"
            title="Instagram Hautes Côtes"
          >
            <img
              :src="`${$URL}assets/img/logo_instagram.webp`"
              alt="logo instagram"
            />
            <span>@hautes_cotes</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

export default {
  name: "Newsletter",
  inject: ["$URL"],
  props: {
    context: {
      type: String,
      default: () => {
        return "component";
      },
    },
    additionalClasses: {
      type: String,
      default: () => {
        return "vertical";
      },
    },
  },
  setup() {
    const { t } = useI18n();
    const form = ref(null);

    const handleClick = () => {
      if (form.value.querySelector(".email").value) {
        form.value.querySelector(".content").classList.add("hidden");
        form.value.querySelector(".success").classList.remove("hidden");
      }
    };

    return { t, handleClick, form };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
.newsletter {
  color: #473d38;
  padding: 8rem 0;
  width: 100%;
  @include respond-to("md-down") {
    flex-direction: column;
    align-items: start;
  }
  & h2 {
    margin: 0 10%;
    max-width: 60%;
    @include respond-to("md-down") {
      max-width: 100%;
    }
  }
  & .form {
    margin: 0 10%;
    width: 80%;
    display: flex;
    align-items: center;
    color: #6a605b;
    margin-top: 6rem;
    @include respond-to("md-down") {
      width: 85%;
      margin-top: 2rem;
    }
    & input[type="text"] {
      width: 60%;
      height: 5rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0 2em;
      @include respond-to("md-down") {
        height: 4rem;
        width: 70%;
      }
    }
    & input[type="submit"] {
      background: rgba(0, 0, 0, 0.1);
      font-size: 14px;
      height: 5rem;
      width: 20%;
      cursor: pointer;
      @include respond-to("md-down") {
        width: 25%;
        height: 4rem;
      }
    }
  }
  .social-links {
    margin-top: 6rem;
    width: 80%;
    @include respond-to("md-down") {
      margin-top: 2rem;
      width: 85%;
    }
    a {
      align-items: flex-end;
      display: flex;
      gap: 10px;
    }
    img {
      width: 30px !important;
      height: 30px;
    }
  }
}
.newsletter.horizontal {
  align-items: center;
  padding: 9.375rem;
  @include respond-to("md-down") {
    padding: 80px 10%;
    align-items: flex-start;
  }

  .title h1,
  .title h2 {
    margin: 0;
    max-width: 35rem;

    @include respond-to("md-down") {
      max-width: 100%;
      margin-bottom: 2rem;
    }
  }
  &:not(.fullsize) {
    .newsletter-form-container {
      width: 40%;
      @include respond-to("md-down") {
        width: 100%;
      }
    }
  }
  .form.content {
    margin: 0;
    width: 100%;

    .email {
      width: 80%;
    }
  }
}
.newsletter.vertical {
  .social-links {
    margin-bottom: 0;
    margin-left: 10%;
    margin-right: 10%;
  }
}

.text {
  display: block;
  padding-top: 2.5rem;
  margin: 0 10%;
  font-size: 14px;
}
.hidden {
  display: none !important;
}
.newsletter.page {
  height: 100vh;
  min-height: 550px;
  display: flex;

  .block-content {
    align-items: flex-start;
    display: flex;
    gap: 30px;

    @include respond-to("md-down") {
      flex-direction: column;
    }

    .title,
    .newsletter-form-container {
      width: 50%;

      @include respond-to("md-down") {
        width: 100%;
      }
    }
    .newsletter-form-container {
      margin-top: 75px;
      @include respond-to("md-down") {
        margin-top: 0;
      }
    }

    .description {
      margin-bottom: 30px;
      padding-right: 20%;
      font-size: max(13px, 1.3rem);
    }
  }
}
</style>
