<template>
  <div class="container experiences" ref="page">
    <div class="block inspired">
      <h1 class="xxl-title">{{ t("experiences.inspired.title") }}</h1>
      <div class="content">
        <p class="text sub">{{ t("experiences.inspired.subtitle") }}</p>
        <p class="text">{{ t("experiences.inspired.text.0") }}</p>
      </div>

      <div class="slider">
        <div class="slider-nav prev" @click="slider.prevSlide"></div>
        <div class="slider-container">
          <div
            class="slider-items"
            @mousedown="slider.onMouseDown"
            @mouseup="slider.onMouseUp"
            @mouseleave="slider.onMouseUp"
            @mousemove="slider.onMouseMove"
            @touchstart="slider.onTouchStart"
            @touchend="slider.onTouchEnd"
          >
            <div
              class="slide"
              v-for="(slide, index) in slider.slides"
              :key="slide.id"
              :class="{ 'item-active': index === slider.activeSlide }"
            >
              <img
                :src="`${$URL}${slide.image}`"
                alt=""
                draggable="false"
                @click="slider.goToSlide(index)"
              />
            </div>
          </div>
        </div>
        <div class="slider-nav next" @click="slider.nextSlide"></div>
      </div>
    </div>

    <div class="exhibition dark content-right content-with-galleryTeaser">
      <div class="head-bg-img"></div>
      <div class="block content">
        <div class="block-title">
          <h2 class="xxl-title">{{ t("experiences.exhibition.title") }}</h2>
        </div>
        <div class="block-content">
          <div class="galleryTeaser">
            <div class="gallery-item item-left">
              <img
                class="image-left"
                :src="`${$URL}assets/img/experiences/experiences_exhibition_left.webp`"
                alt=""
                data-speed="50"
              />
            </div>
            <div class="gallery-item item-front">
              <img
                class="image-front"
                :src="`${$URL}assets/img/experiences/experiences_exhibition_front.webp`"
                alt=""
                data-speed="10"
              />
            </div>
            <div class="gallery-item item-right">
              <img
                class="image-right"
                :src="`${$URL}assets/img/experiences/experiences_exhibition_right.webp`"
                alt=""
                data-speed="50"
              />
            </div>
          </div>
          <div class="block-content-text text">
            <h3 class="subtitle">{{ t("experiences.exhibition.subtitle") }}</h3>
            <p class="text">{{ t("experiences.exhibition.text") }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="wine-hiphop darker content-left content-with-galleryTeaser">
      <div class="head-bg-img"></div>
      <div class="block content">
        <div class="block-title">
          <h2 class="xxl-title">{{ t("experiences.wine_hiphop.title") }}</h2>
        </div>

        <div class="block-content">
          <div class="block-content-text text">
            <h3 class="subtitle">
              {{ t("experiences.wine_hiphop.subtitle") }}
            </h3>
            <p class="text">{{ t("experiences.wine_hiphop.text") }}</p>
          </div>
          <div class="galleryTeaser">
            <div class="gallery-item item-left">
              <img
                class="image-left"
                :src="`${$URL}assets/img/experiences/experiences_wine_hiphop_left.webp`"
                alt=""
                data-speed="50"
              />
            </div>
            <div class="gallery-item item-front">
              <img
                class="image-front"
                :src="`${$URL}assets/img/experiences/experiences_wine_hiphop_front.webp`"
                alt=""
                data-speed="10"
              />
            </div>
            <div class="gallery-item item-right">
              <img
                class="image-right"
                :src="`${$URL}assets/img/experiences/experiences_wine_hiphop_right.webp`"
                alt=""
                data-speed="50"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="block dinners">
      <div class="content">
        <h2 class="xxl-title">{{ t("experiences.dinners.title") }}</h2>
        <p class="text">{{ t("experiences.dinners.text.0") }}</p>
      </div>
      <div class="picture">
        <img
          :src="`${$URL}assets/img/experiences/experiences_dinners.webp`"
          alt=""
        />
        <div class="caption">
          <div class="s-title">{{ t("experiences.dinners.subtitle.0") }}</div>
          <div class="signature">{{ t("experiences.dinners.subtitle.1") }}</div>
        </div>
      </div>
    </div>

    <div class="roadside content-right content-with-galleryTeaser">
      <div class="head-bg-img"></div>
      <div class="block content">
        <div class="block-title">
          <h2 class="xxl-title">{{ t("experiences.roadside.title") }}</h2>
        </div>
        <div class="block-content">
          <div class="galleryTeaser">
            <div class="gallery-item item-left">
              <img
                class="image-left"
                :src="`${$URL}assets/img/experiences/experiences_roadside_left.webp`"
                alt=""
                data-speed="50"
              />
            </div>
            <div class="gallery-item item-front">
              <img
                class="image-front"
                :src="`${$URL}assets/img/experiences/experiences_roadside_front.webp`"
                alt=""
                data-speed="10"
              />
            </div>
            <div class="gallery-item item-right">
              <img
                class="image-right"
                :src="`${$URL}assets/img/experiences/experiences_roadside_right.webp`"
                alt=""
                data-speed="50"
              />
            </div>
          </div>
          <div class="block-content-text text">
            <h3 class="subtitle">{{ t("experiences.roadside.subtitle") }}</h3>
            <p class="text">{{ t("experiences.roadside.text") }}</p>
          </div>
        </div>
      </div>
    </div>

    <PlanExperience />

    <div class="footer">
      <Newsletter />
      <ArtChoice />
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { onMounted, reactive, ref } from "vue";

import splitText from "@joinbox/splittext";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import ArtChoice from "@/components/ArtChoice";
import Newsletter from "@/components/Newsletter";
import PlanExperience from "@/components/PlanExperience";

export default {
  name: "Experiences",
  inject: ["$URL"],
  components: {
    ArtChoice,
    Newsletter,
    PlanExperience,
  },
  setup() {
    const { t } = useI18n();
    const page = ref(null);
    const slider = reactive({
      currentSlide: 0,
      slidesToShow: 3,
      activeSlide: 1,
      maxSlides: 6,
      minSlides: 0,
      slides: [
        { image: "assets/img/experiences/experiences_slide_2.webp", id: 1 },
        { image: "assets/img/experiences/experiences_slide_1.webp", id: 2 },
        { image: "assets/img/experiences/experiences_slide_3.webp", id: 3 },
        { image: "assets/img/experiences/experiences_slide_4.webp", id: 4 },
        { image: "assets/img/experiences/experiences_slide_5.webp", id: 5 },
        { image: "assets/img/experiences/experiences_slide_6.webp", id: 6 },
      ],
      touchStartX: 0,
      touchEndX: 0,
      isDragging: false,
      nextSlide() {
        if (this.currentSlide < this.maxSlides) {
          this.currentSlide++;
        }
        this.updateSliderPosition();
      },
      prevSlide() {
        if (this.currentSlide > this.minSlides) {
          this.currentSlide--;
        }
        this.updateSliderPosition();
      },
      goToSlide(index) {
        this.currentSlide = index - 1;
        this.updateSliderPosition();
      },

      updateSliderPosition() {
        this.activeSlide = this.currentSlide;
        if (this.slidesToShow > 1) {
          this.activeSlide++;
        }
        const sliderElement = document.querySelector(".slider-items");
        if (sliderElement) {
          const slideWidth = document.querySelector(
            ".slider-items .slide"
          ).clientWidth;
          const transformValue = -(slideWidth * this.currentSlide);
          sliderElement.style.transform = `translateX(${transformValue}px)`;
        }
      },

      onMouseDown(event) {
        event.preventDefault();
        this.isDragging = true;
        this.touchStartX = event.clientX;
      },
      onMouseUp(event) {
        if (this.isDragging) {
          this.touchEndX = event.clientX;
          this.handleSwipe();
        }
        this.isDragging = false;
      },
      onMouseMove(event) {
        if (this.isDragging) {
          this.touchEndX = event.clientX;
        }
      },
      handleSwipe() {
        // Détermination de la direction du swipe et du drag
        const threshold = 50; // Définir un seuil pour ignorer les petits mouvements
        const deltaX = this.touchEndX - this.touchStartX;
        if (Math.abs(deltaX) > threshold) {
          if (deltaX < 0) {
            this.nextSlide();
          } else {
            this.prevSlide();
          }
        }
      },
      onTouchStart(event) {
        this.touchStartX = event.changedTouches[0].clientX;
      },

      onTouchEnd(event) {
        this.touchEndX = event.changedTouches[0].clientX;
        this.handleSwipe();
      },
    });

    const updateSlidesToShow = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1024) {
        // Ajustez en fonction de votre point de rupture
        slider.slidesToShow = 1;
      } else {
        slider.slidesToShow = 3; // Revenir au nombre normal de slides pour les écrans plus larges
      }

      slider.maxSlides = slider.slides.length - 1;
      if (slider.slidesToShow === 3) {
        slider.maxSlides--;
      }
      slider.minSlides = 0;
      if (slider.slidesToShow === 3) {
        slider.minSlides--;
      }
      if (slider.currentSlide > slider.maxSlides) {
        slider.currentSlide = slider.maxSlides;
      } else if (slider.currentSlide < slider.minSlides) {
        slider.currentSlide = slider.minSlides;
      }
      slider.updateSliderPosition(); // Assurez-vous de recalculer la position du slider
    };

    window.addEventListener("resize", updateSlidesToShow);
    updateSlidesToShow(); // Appel initial pour définir le bon nombre de slides

    onMounted(() => {
      page.value.querySelectorAll(".galleryTeaser img").forEach((element) => {
        ScrollTrigger.create({
          trigger: ".sense",
          start: "top bottom",
          end: "bottom top",
          onUpdate() {
            if (ScrollTrigger.isInViewport(element)) {
              gsap.to(element, 0, {
                yPercent:
                  ScrollTrigger.positionInViewport(element, "top") *
                  element.dataset.speed,
                ease: "none",
              });
            }
          },
        });
      });

      page.value.querySelectorAll(".xxl-title:not(h1)").forEach((element) => {
        splitText({
          element: element,
          wrapLetter: (content, index) =>
            `<span class='letter' style='--splitTextIndex: ${index}'>${content}</span>`,
          wrapWord: (content, index) =>
            `<span class='wordW' style='--splitTextIndex: ${index}'><span class="word">${content}</span></span>`,
          updateOnResize: false,
        });
        ScrollTrigger.create({
          start: 0,
          end: "max",
          onUpdate() {
            if (
              ScrollTrigger.isInViewport(element) &&
              ScrollTrigger.positionInViewport(element, "top") <= 0.75
            ) {
              gsap.to(element.querySelectorAll(".word"), {
                delay: 0,
                duration: 1.3,
                translateY: 0,
                opacity: 1,
                ease: "power4.out",
                stagger: {
                  from: "start",
                  amount: 0.2,
                },
              });
            }
          },
        });
      });
    });

    return { t, page, slider };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
@import "@/styles/contentWithGalleryTeaser.scss";
.xxl-title {
  white-space: pre-line;
  @include respond-to("lg-down") {
    @include respond-to("md") {
      white-space: inherit;
    }
  }
  @include respond-to("md-down") {
    white-space: inherit;
  }
  & ::v-deep(.wordW) {
    overflow: hidden;
    width: auto;
    display: inline-block;
    &:not(:last-child) {
      margin: 0 15px;
      @include respond-to("lg-down") {
        @include respond-to("md") {
          margin-right: inherit;
        }
      }
      @include respond-to("md-down") {
        margin: 0 5px;
      }
    }
    & .word {
      opacity: 0;
      display: inline-block;
      transform: translateY(100%);
      & .letter {
        display: inline;
      }
    }
  }
}

.inspired {
  & h1 {
    color: #473d38;
    margin-bottom: 50px;
    max-width: 100%;
    text-align: center;

    @include respond-to("md-down") {
      max-width: 100%;
      font-size: 32px;
      line-height: 32px;
    }
  }
  & .content {
    text-align: center;

    .text {
      margin: 0 auto;
      max-width: 843px;
    }
    .sub {
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 25px;
      text-transform: uppercase;
    }
  }

  & .slider {
    margin-top: 50px;
    position: relative;

    .slider-container {
      margin: 0 50px;
      overflow: hidden;
    }

    .slider-items {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      transition: transform 0.5s ease;
    }

    .slide {
      align-items: center;
      aspect-ratio: 0.6;
      display: flex;
      justify-content: center;
      flex: 0 0 auto;
      text-align: center;
      width: 33.333%;

      @include respond-to("lg-down") {
        aspect-ratio: unset;
        width: 100%;
      }

      img {
        max-width: 60%;
        opacity: 0.5;
        transition: max-width 0.5s ease, opacity 0.5s ease;
        width: auto;
      }
    }
    .slide.item-active {
      img {
        opacity: 1;
        max-width: 100%;
        width: auto !important;
      }
    }

    .slider-nav {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &.prev {
        cursor: pointer;
        left: 0;
        &:before {
          content: url("/assets/img/slider-prev.svg");
          display: block;
        }
      }
      &.next {
        cursor: pointer;
        right: 0;

        &:before {
          content: url("/assets/img/slider-next.svg");
          display: block;
        }
      }
    }
  }
}
.exhibition {
  & .head-bg-img {
    background-image: url("/assets/img/experiences/experiences_exhibition_head.webp");
  }
}
.wine-hiphop {
  & .head-bg-img {
    background-image: url("/assets/img/experiences/experiences_wine_hiphop_head.webp");
  }
}
.roadside {
  & .head-bg-img {
    background-image: url("/assets/img/experiences/experiences_roadside_head.webp");
  }
}

.dinners {
  display: flex;
  justify-content: space-between;

  @include respond-to("md-down") {
    flex-direction: column;
  }

  & h2.xxl-title {
    color: #473d38;
    white-space: pre-line;
    line-height: 1em;
    margin-top: 5rem;
    font-size: 5.625rem;
    @include respond-to("md-down") {
      font-size: 35px;
      margin-top: 0;
    }
  }
  & .picture,
  & .content {
    width: 50%;
    @include respond-to("md-down") {
      width: 100%;
    }
  }
  & .picture {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 15rem;

    @include respond-to("md-down") {
      gap: 3.5rem;
    }

    & img {
      max-width: 100%;

      @include respond-to("md-down") {
        width: auto !important;
      }
    }

    & .s-title {
      font-size: 2.1rem;
    }
    & .caption {
      font-size: 28px;
      text-align: center;
      max-width: 500px;

      & .signature {
        font-size: 16px;
        margin-top: 4rem;

        @include respond-to("md-down") {
          margin-top: 2rem;
        }
      }
    }
  }
}

.plan {
  background-color: #b79c87;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @include respond-to("md-down") {
    flex-direction: column;
  }
  & .content {
    max-width: 45rem;
    text-align: center;
    color: #f5ebe6;
    & p {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      font-size: 14px;
      opacity: 0.8;
    }
    & h2 {
      margin-bottom: 5rem;
      color: #f5ebe6;
      @include respond-to("md-down") {
        margin-bottom: 2rem;
        margin-top: 2rem;
      }
    }
    & a {
      background: rgba(255, 255, 255, 0.1);
      font-size: 14px;
      padding: 20px 40px;
      text-decoration: none;
      display: inline-block;
      margin-top: 2rem;
      @include respond-to("md-down") {
        display: block;
        width: 70%;
        margin: 0 auto;
      }
    }
  }
}

.footer {
  display: flex;
  @include respond-to("md-down") {
    flex-direction: column;
  }
}
</style>
