<template>
  <div class="container wine" ref="page">
    <div class="pageIntro dark">
      <h1 class="s-title">
        {{ t("wine.top.text") }}
      </h1>
      <span>
        {{ t("wine.top.cta") }}
      </span>
    </div>
    <div class="block enhance">
      <h2 class="xxl-title">{{ t("wine.enhance.title") }}</h2>
      <div class="content">
        <div class="picture">
          <img :src="`${$URL}assets/img/wine_experiences_top.webp`" alt="" />
          <img :src="`${$URL}assets/img/wine_experiences_bottom.webp`" alt="" />
        </div>
        <div>
          <p class="text">{{ t("wine.enhance.text.0") }}</p>
          <p class="s-title">{{ t("wine.enhance.subtitle.0") }}</p>
          <p class="text">{{ t("wine.enhance.text.1") }}</p>
          <p class="s-title">{{ t("wine.enhance.subtitle.1") }}</p>
          <p class="text">{{ t("wine.enhance.text.2") }}</p>
          <p class="s-title">{{ t("wine.enhance.subtitle.2") }}</p>
          <p class="text">{{ t("wine.enhance.text.3") }}</p>
        </div>
      </div>
    </div>
    <div class="dark">
      <div class="block sense">
        <h2 class="xxl-title">{{ t("wine.sense.title") }}</h2>
        <p class="text">{{ t("wine.sense.text") }}</p>
        <div class="galleryTeaser">
          <img
            :src="`${$URL}assets/img/wine_sense_right.webp`"
            alt=""
            data-speed="50"
          />
          <img
            :src="`${$URL}assets/img/wine_sense_left.webp`"
            alt=""
            data-speed="50"
          />
          <img
            :src="`${$URL}assets/img/wine_sense_front.webp`"
            alt=""
            data-speed="10"
          />
        </div>
      </div>
      <div class="custom">
        <div class="head"></div>
        <div class="block content">
          <h2 class="xxl-title">{{ t("wine.custom.title") }}</h2>
          <div>
            <p class="text">{{ t("wine.custom.text.0") }}</p>
          </div>
        </div>
      </div>
    </div>

    <WhoWeAre currentPage="wine" />
    <PlanExperience />

    <div class="footer">
      <Newsletter />
      <ArtChoice />
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";

import splitText from "@joinbox/splittext";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import ArtChoice from "@/components/ArtChoice";
import Newsletter from "@/components/Newsletter";
import WhoWeAre from "@/components/WhoWeAre";
import PlanExperience from "@/components/PlanExperience";

export default {
  name: "Wine",
  inject: ["$URL"],
  components: {
    ArtChoice,
    Newsletter,
    WhoWeAre,
    PlanExperience,
  },
  setup() {
    const { t } = useI18n();
    const page = ref(null);

    onMounted(() => {
      page.value.querySelectorAll(".galleryTeaser img").forEach((element) => {
        ScrollTrigger.create({
          trigger: ".sense",
          start: "top bottom",
          end: "bottom top",
          scrub: true,
          onUpdate() {
            if (ScrollTrigger.isInViewport(element)) {
              gsap.to(element, 0, {
                yPercent:
                  ScrollTrigger.positionInViewport(element, "top") *
                  element.dataset.speed,
                ease: "none",
              });
            }
          },
        });
      });

      page.value.querySelectorAll(".xxl-title").forEach((element) => {
        splitText({
          element: element,
          wrapLetter: (content, index) =>
            `<span class='letter' style='--splitTextIndex: ${index}'>${content}</span>`,
          wrapWord: (content, index) =>
            `<span class='wordW' style='--splitTextIndex: ${index}'><span class="word">${content}</span></span>`,
          updateOnResize: false,
        });
        ScrollTrigger.create({
          start: 0,
          end: "max",
          onUpdate() {
            if (
              ScrollTrigger.isInViewport(element) &&
              ScrollTrigger.positionInViewport(element, "top") <= 0.75
            ) {
              gsap.to(element.querySelectorAll(".word"), {
                delay: 0,
                duration: 1.3,
                translateY: 0,
                opacity: 1,
                ease: "power4.out",
                stagger: {
                  from: "start",
                  amount: 0.2,
                },
              });
            }
          },
        });
      });
    });

    return { t, page };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
.xxl-title {
  white-space: pre-line;
  @include respond-to("lg-down") {
    @include respond-to("md") {
      white-space: inherit;
    }
  }
  @include respond-to("md-down") {
    white-space: inherit;
  }
  & ::v-deep(.wordW) {
    overflow: hidden;
    width: auto;
    display: inline-block;
    &:not(:last-child) {
      margin: 0 15px;
      @include respond-to("lg-down") {
        @include respond-to("md") {
          margin-right: inherit;
        }
      }
      @include respond-to("md-down") {
        margin: 0 5px;
      }
    }
    & .word {
      opacity: 0;
      display: inline-block;
      transform: translateY(100%);
      & .letter {
        display: inline;
      }
    }
  }
}

.pageIntro {
  color: #f5ebe6;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  // background-color: #1d281d;
  background-image: url("/assets/img/wine_abstract.webp");
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  & h1 {
    max-width: 57.5rem;
    text-align: center;
    @include respond-to("md-down") {
      max-width: 90%;
    }
  }
  & span {
    text-transform: uppercase;
    position: absolute;
    bottom: 10%;
    font-size: 14px;
  }
}

.enhance {
  & h2 {
    color: #473d38;
    max-width: 8em;
  }
  & .content {
    padding: 8rem 0 0;
    display: flex;
    & p.text {
      margin-bottom: 1em;
    }
    & p.s-title {
      font-size: 26px;
    }
    @include respond-to("md-down") {
      padding: 2rem 0;
      flex-direction: column;
    }
    & .picture {
      width: 40%;
      margin-right: 15%;
      margin-top: 10rem;
      position: relative;
      @include respond-to("md-down") {
        padding: 0;
        margin: 2rem 0;
        width: 100%;
      }
      & img {
        position: absolute;
        &:first-child {
          right: 0;
          top: 0;
        }
        &:last-child {
          left: 0;
          bottom: 15%;
          @include respond-to("md-down") {
            display: none;
          }
        }
        @include respond-to("md-down") {
          min-width: 100%;
          position: inherit;
        }
      }
    }
  }
}

.sense {
  color: #f5ebe6;
  background-color: #1d281d;
  display: flex;
  flex-direction: column;
  align-items: center;
  & h2 {
    color: #f5ebe6;
    text-align: center;
    margin: 5rem 0;
    @include respond-to("md-down") {
      margin: 0 0 2rem;
    }
  }
  & p {
    text-align: center;
    max-width: 50%;
    @include respond-to("md-down") {
      max-width: 100%;
    }
  }
  & .galleryTeaser {
    position: relative;
    height: 52rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @include respond-to("md-down") {
      height: 24rem;
      padding-top: 2rem;
      width: 100%;
      margin-bottom: 2rem;
    }
    & img {
      position: absolute;
      @include respond-to("md-down") {
        position: inherit;
      }
      &:first-child {
        height: 20rem;
        transform: translate3d(20rem, -15rem, 0);
        z-index: 2;
        @include respond-to("md-down") {
          display: none;
        }
        @include respond-to("lg-down") {
          @include respond-to("md") {
            height: 40rem;
            transform: translate3d(9rem, 4rem, 0) !important;
          }
        }
      }
      &:nth-child(3) {
        height: 40rem;
        z-index: 1;
        @include respond-to("md-down") {
          min-width: 100%;
          height: auto;
          transform: translate3d(0, 0, 0) !important;
        }
        @include respond-to("lg-down") {
          @include respond-to("md") {
            height: 40rem;
            transform: translate3d(-9rem, -4rem, 0) !important;
          }
        }
      }
      &:nth-child(2) {
        z-index: 2;
        height: 20rem;
        transform: translate3d(-20rem, 5rem, 0);
        @include respond-to("md-down") {
          display: none;
        }
        @include respond-to("lg-down") {
          @include respond-to("md") {
            transform: translate3d(0rem, 0rem, 0) !important;
            height: 40rem;
          }
        }
      }
    }
  }
  & .cta {
    text-transform: capitalize;
    margin-bottom: 0;
  }
}

.custom {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #1d281d;
  // @include respond-to("md-down") {
  //   padding-top: 17rem;
  //   padding-bottom: 0;
  //   flex-direction: column-reverse;
  // }
  & .head {
    position: absolute;
    top: 0;
    width: 100%;
    height: 80vh;
    margin-top: 0;
    background-image: url("/assets/img/wine_values.webp");
    background-size: cover;
    background-position: center center;
  }
  & .content {
    width: 55%;
    z-index: 2;
    padding-top: 74vh;
    right: 0;
    color: #f5ebe6;
    @include respond-to("md-down") {
      justify-content: flex-start;
      flex-direction: column-reverse;
      padding-top: 77vh;
      width: 100%;
    }
    & h2 {
      color: #f5ebe6;
      text-align: left;
      max-width: 45rem;
      margin-bottom: 5rem;
    }
  }
}

.footer {
  display: flex;
  @include respond-to("md-down") {
    flex-direction: column;
  }
}
</style>
